import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

import styled from 'styled-components'

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}))(MuiDialogContent);

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0;
    color: #224261;
    font-weight: 700;
    font-size: 18px;
  }

  p {
    margin: 8px 0 24px;
    color: #607A94;

  }

  button {
    width: 100%;
    max-width: 290px;
    padding: 0 24px;
    height: 48px;
    color: #FFFFFF;
    background: #0587FF;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s linear;

    &:hover {
      background: #0570FF;
    }
  }
`