import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Container, CircularProgress, Box, Button } from '@material-ui/core';
import useRastreio from '../../hooks/useRastreio';
import Loading from '../../components/Loading/Loading';
import history from '../../services/history';
import { RastreioCorreios } from '../../components/RastreioCorreios';
import { toast } from 'react-toastify';

const RastreioConsulta = (props) => {
    const [animation, setAnimation] = useState(true);
    const { state: { rastreio, loading } } = useRastreio(props.match.params.codigoRastreio, history.location?.state)

    if (loading) return <Loading />;

    if (!loading && !rastreio) {
        return <Loading />;
    }

    if (!loading && rastreio && animation) {
        const element = document.getElementById('progress');
        element.classList.add('active');
        setTimeout(() => {
            element.classList.add('finish');
        }, 2000);

        setTimeout(() => {
            animationFinish();
        }, 4000);

        return <Loading />;
    }

    function animationFinish() {
        setAnimation(false);
    }

    const options = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "0 auto",
    }

    ReactGA.pageview('/RastreioConsulta');

    if (rastreio.transportadoraTipo === 0 && rastreio.linkRastreio === '') {
        history.push('/');
        toast.error("Pedido não encontrado ou código de rastreio inválido.");
    } else if (rastreio.redirect || rastreio.transportadoraTipo == 10) {
        return (
            <>
                <Container>
                    <CircularProgress size={60} style={{ 'color': '#0587FF' }} />
                    <h1 style={{ marginBottom: "50px", marginTop: "50px" }}>Você será redirecionado para a página da transportadora</h1>
                    {redirect()}
                </Container>
            </>
        )
    } else if (rastreio.ocorrencias && rastreio.ocorrencias.length === 0) {
        history.push('/correios');
        toast.error("Pedido não encontrado ou código de rastreio inválido.")
    } else if (rastreio.ocorrencias && rastreio.ocorrencias.length > 0) {
        return (
            <RastreioCorreios
                rastreio={rastreio}
            />
        )
    }

    function clickVoltar() {
        history.push('/');
    }

    function redirect() {
        setTimeout(() => {
            window.location.href = rastreio.linkRastreio;
        }, 1000);
    }

    function clickAcessarSite() {
        window.location.href = rastreio.linkRastreio;
    }

    return (
        <>
            <Container className="container-frame">
                <Box className="header-frame">
                    <Button onClick={() => { clickVoltar(); }}>
                        <span className="material-icons">&#xe5cb;</span>
                        <span className="txt">Voltar</span>
                    </Button>
                    <Box className="name-transportadora">
                        <span className="txt-title">Transportadora</span>
                        <span className="txt-name">{rastreio.transportadoraServico}</span>
                    </Box>
                    <Button onClick={() => { clickAcessarSite(); }}>
                        <span className="txt">Acessar site</span>
                        <span className="material-icons">&#xe5cc;</span>
                    </Button>
                </Box>
                <iframe src={rastreio.linkRastreio} title="Histórico do Rastreio" style={options} width="100%" height="700"></iframe>
            </Container>
        </>
    );
};

export default RastreioConsulta;