import { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import history from '../../services/history';
import logoCorreios from '../../assets/logo-correios.svg';
import ReactGA from 'react-ga';
import ReCAPTCHA from "react-google-recaptcha";
import recaptchaConfig from '../../config/recaptcha'
import './recaptcha.css';
import ModalDialog from '../../components/ModalDialog';

const schema = Yup.object().shape({
    codigoRastreio: Yup.string()
        .required('O código de rastreio é obrigatório')
});

const Rastreio = () => {
    const recaptchaRef = useRef();
    ReactGA.pageview('/RastreioBuscaCorreios');

    const [codigo, setCodigo] = useState(history.location?.state);

    const OnSubmitWithReCAPTCHA = async () => {
        return await recaptchaRef.current.executeAsync()
    }

    const [open, setOpen] = useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    async function handleSubmit(data) {
        const recaptchaToken = await OnSubmitWithReCAPTCHA()

        ReactGA.event({
            category: 'User',
            action: 'Buscou por um Código de Rastreio dos Correios'
        });

        history.push(`/correios/${data.codigoRastreio}`, recaptchaToken)

        // Serviço temporariamente indisponível
        // handleClickOpen()
    }

    document.title = 'Portal de Rastreio Correios - Magazord'

    return (
        <div className="container-form">
            <div className="box-form">
                <div className="box-info">
                    <img src={logoCorreios} alt="Logo Correios" className="logo-correios" />
                    <p>Informe abaixo o código de rastreio dos Correios,</p>
                    <p>para acompanhar sua encomenda.</p>
                </div>
                <Form schema={schema} onSubmit={handleSubmit}>
                    <div className="form-group">
                        <Input name="codigoRastreio" placeholder="Ex: SL123456789BR" value={codigo} onChange={(e) => setCodigo(e.value)} />
                    </div>

                    <Button className="btn-default busca-correios" type="submit" value="Acessar">
                        Rastrear Pedido Correios
                    </Button>

                    <div className="recaptcha">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={recaptchaConfig.recaptchaToken}
                            badge="inline"
                        />
                    </div>
                </Form>
            </div>

            {/* <ModalDialog 
                open={open}
                handleClose={handleClose}
            /> */}
        </div>
    )
}

export default Rastreio;