import styled from 'styled-components';

export const Headerstyles = styled.div`
  position: relative;
  display: flex;
`;

export const RedirectHome = styled.button`
  padding: 20px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #0587FF;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;

  @media (max-width: 340px) {
    left: -6px;
  }
`

export const LogoMagazord = styled.img`
  @media (max-width: 340px) {
      width: 160px;
      height: 40px;
    }
`