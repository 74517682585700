import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Wrapper, Content, Center } from './styles';
export default function AuthLayout({ children }) {
  return (
      <>
        <Center>
            <Wrapper className="main-area">
                <Header/>
                <Content>{children}</Content>
                <Footer/>
            </Wrapper>
        </Center>
      </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
