import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../pages/layout';

export default function RouteWrapper({
    component: Component,
    isPrivate,
    role,
    path,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}
