import api from './api';

class RastreioServices {

    /**
     * @param codigoRastreio
     * @returns {Promise<void>}
     */

    static async getRastreio(codigoRastreio, recaptchaKey) {

        if (window.location.pathname.includes('/correios')) {
            const { data } = await api.get(`/rastreio/correios`,
                {
                    params:
                        { codigoRastreio, recaptchaKey }
                });
            return data;
        }

        const { data } = await api.get(`/rastreio`, { params: { codigoRastreio } });
        return data;
    }

}

export default RastreioServices;
