import styled from 'styled-components';

export const Footerstyles = styled.div`
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid #F2F2F2;

  p{
    font-size: 11px;
    color: #989898;
    margin: 0;
    letter-spacing: 0.3px;
  }

  span {
    display: block;
    font-size: 11px;
    color: #989898;
    margin: 0 0 8px;
    letter-spacing: 0.3px;
  }

  a{
    font-size: 11px;
    color: #4e4e4e;
    text-decoration: none;
    letter-spacing: 0.3px;

    &:hover{
        color: #0587FF;
    }
}
`

export const LogoMagazord = styled.img`
  display: block;
  margin: 15px auto 5px;
  max-width: 200px;

  @media (max-width: 340px) {
      width: 160px;
      height: 40px;
    }
`