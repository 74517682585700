import React from 'react';
import history from '../../services/history';

import { Headerstyles, RedirectHome, LogoMagazord } from './styles';
import logo from '../../assets/logo-magazord.svg';
import chevronLeft from '../../assets/chevron-left.svg';

const Header = () => {
    const url = window.location.pathname

    return (
        <Headerstyles>
            {url.includes('correios') &&
                <RedirectHome className="redirect-home" onClick={() => history.push('/')}>
                    <img src={chevronLeft} alt="Ícone voltar" width={30} height={30} />
                    Voltar
                </RedirectHome>
            }
            {/* {url.includes('correios') ||
                <LogoMagazord src={logo} alt="Magazord Digital Commerce" />
            } */}
        </Headerstyles>
    );
};

export default Header;