import axios from 'axios';
const api = axios.create({
  baseURL: process.env.REACT_APP_MAGAZORD_API,
});
// Autenticação padrão dos requests
api.interceptors.request.use((config) => {
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    console.log(response);
    throw error;

  }
);
export default api;
