import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import { RastreioBusca, RastreioConsulta, RastreioBuscaCorreios } from '../pages/MainPage';

export default function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={RastreioBusca} />
            <Route path="/correios" exact component={RastreioBuscaCorreios} />
            <Route path="/correios/:codigoRastreio" exact component={RastreioConsulta} />
            <Route path="/:codigoRastreio" exact component={RastreioConsulta} />
        </Switch>
    )
}