import React from 'react';
import { Footerstyles, LogoMagazord } from './styles';
import logoMagazord from '../../assets/logo-magazord.svg'

const Footer = () => {
    const url = window.location.pathname

    return (
        <Footerstyles>

            {url.includes('correios') &&
                <span>
                    Este site é protegido pelo reCAPTCHA e são aplicáveis as <a href="https://policies.google.com/privacy?hl=pt-BR" target="_blank" rel="noopener noreferrer">Políticas de Privacidade</a> e <a href="https://policies.google.com/terms?hl=pt-BR" target="_blank" rel="noopener noreferrer">Termos de Serviço do Google</a>.
                </span>
            }
            <p>* A MAGAZORD não é responsável pelo transporte/envio dos objetos.</p>
            <p>Neste site apenas buscamos as informações com a transportadora para facilitar sua visualização do estado da entrega.</p>
            <p>Caso você possua alguma dúvida sobre a sua encomenda, entre em contato diretamente com a loja onde você realizou a compra ou com a transportadora.</p>

            {/* <a href="https://www.magazord.com.br/?utm_source=portal_rastreio_magazord&utm_medium=link_magazord_rodape&utm_campaign=acesso_portal_rastreio_magazord" target="_blank" rel="nofollow noopener noreferrer"> */}
                <LogoMagazord src={logoMagazord} alt="Magazord Digital Commerce" />
            {/* </a> */}
        </Footerstyles>
    );
};

export default Footer;