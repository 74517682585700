import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';
import history from './services/history'
import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING_ID);

const themeLight = createTheme({
  palette: {
    background: {
      default: "#E5E5E5"
    },
  }
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#222222"
    }
  }
});

function App() {
  const [light, setLight] = React.useState(true);
  return (
    <MuiThemeProvider theme={light ? themeLight : themeDark}>
      <Router history={history}>
        <CssBaseline />
        <Routes />
        <ToastContainer autoClose={3000} />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
