import history from '../../services/history';

import {
    CorreiosContentSubtitle,
    CorreiosContentTitle,
    CorreiosContentWrapper,
    CorreiosRedirectButton,
    CorreiosSection,
    CorreiosContentImg,
} from '../../pages/layout/styles';

import correiosImg from '../../assets/correios.png';

export function CorreiosCta() {
    return (
        <CorreiosSection>
            <CorreiosContentImg src={correiosImg} alt="Entregador Correios" />

            <CorreiosContentWrapper>
                <CorreiosContentTitle>Pediu com os Correios?</CorreiosContentTitle>
                <CorreiosContentSubtitle>Temos uma página especial para rastrear seu pedido!</CorreiosContentSubtitle>

                <CorreiosRedirectButton onClick={() => history.push('/correios')}>
                    Clique aqui
                </CorreiosRedirectButton>
            </CorreiosContentWrapper>
        </CorreiosSection>
    )
}