import ReactGA from 'react-ga';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import history from '../../services/history';
import truckImg from '../../assets/truck.svg';

import { CorreiosCta } from '../../components/CorreiosCta';

const schema = Yup.object().shape({
    codigoRastreio: Yup.string()
        .required('O código de rastreio é obrigatório')
});

const Rastreio = () => {
    ReactGA.pageview('/RastreioBusca');
    async function handleSubmit(data) {

        ReactGA.event({
            category: 'User',
            action: 'Buscou por um Código de Rastreio'
        });

        history.push(`/${data.codigoRastreio}`)
    }

    document.title = 'Portal de Rastreios - Magazord'

    return (
        <>
            <div className="container-form">
                <div className="box-form">
                    <div className="box-info">
                        <span><img src={truckImg} alt="Caminhão" /></span>
                        <h1>Central de rastreios</h1>
                        <p>Informe abaixo o código de rastreio do seu pedido,</p>
                        <p>para consultarmos as transportadoras.</p>
                    </div>
                    <Form schema={schema} onSubmit={handleSubmit}>
                        <div className="form-group">
                            <Input name="codigoRastreio" />
                        </div>

                        <button type="submit" value="Acessar">
                            Rastrear Pedido
                        </button>
                    </Form>

                    <CorreiosCta />
                </div>
            </div>
        </>
    );
}

export default Rastreio;