import styled from 'styled-components';

export const Center = styled.div`
  width: 100%;
  margin: 50px auto;
  max-width: 900px;
  

  @media only screen and (max-width: 980px) {
    margin: 0;
    min-height: 100vh;
    background: #fff;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 0 50px;
  box-shadow: 0px 0px 4px rgba(79, 79, 80, 0.15);

  @media only screen and (max-width: 980px) {
    border-radius: 0;
    box-shadow: none;
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 650px;
  text-align: center;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;

  @media only screen and (max-width: 980px) {
    min-height: calc(100vh - 210px);
  }

  .container-form{
      display: flex;
      place-items: center;
      justify-content: center;
  }

  .box-form{
    width: 100%;
    max-width: 490px;
    animation: fadein 1s;
    margin: 40px 0;

    form {
      button {
        cursor: pointer;
      }

      .busca-correios {
        font-size: 14px;
      }
    }
  }

  .box-info{
    width: 100%;
    margin-bottom: 30px;

    .logo-correios {
      width: 200px;
      height: 70px;
      margin-bottom: 18px;

      @media (max-width: 340px) {
        width: 160px;
        height: 40px;
      }
    }
  }

  h1{
      color: #0587FF;
      font-size: 20px;
      margin: 0;
      margin-bottom: 5px;
  }

  p{
      font-size: 14px;
      color: #989898;
      margin: 0;
  }

  .button-correios {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    padding: 12px 18px;
    background: #FFFFFF;
    border-radius: 8px;
    font-size: 14px;
    color: #0587FF;
    border: none;
    text-decoration: none;
    cursor: pointer;
    
    transition: box-shadow 0.2s linear;

    &:hover {
      box-shadow: 0px 4px 24px rgba(18, 45, 71, 0.1);
    }
  }

  form{
    margin-bottom: 16px;

    .form-group{
        position: relative;
        height: 76px;
    }

    input{
        width: 100%;
        background: #F2F2F2;
        height: 48px;
        padding: 10px 25px;
        border-radius: 8px;
        border: none;
        color: #000000;
        text-align: center;
        letter-spacing: 0.3px;

        &:focus{
            outline: 1px solid #737373;
        }

        ~span{
            font-size: 12px;
            color: red;
            display: block;
            margin-top: 10px;
        }
    }

    button {
      width: 205px;
      height: 52px;
      background: #0587FF;
      color: #ffffff;
      font-size: 14px;
      border-radius: 8px;
      text-transform: capitalize;
      padding: 8px 18px;
      margin-top: 10px;
      border: none;

      transition: background 0.2s linear;
  
      &:hover{
         background: #0171d9;
      }
    }
  }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

  .container-frame{
      padding: 0;
      border: 1px solid #DBDBDB;
      border-radius: 8px;
      animation: fadein 1s;
      margin-top: 40px;

      .header-frame{
        width: 90%;
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        margin: 0 5%;
        border-bottom: 2px solid #F2F2F2;

        @media only screen and (max-width: 980px) {
            width: 100%;
            margin: 0;
          }
      }

      .name-transportadora{
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 980px) {
            margin-left: 35px;
        }

          .txt-title{
              font-size: 16px;
              color: #737373;

              @media only screen and (max-width: 980px) {
                font-size: 10px;
              }
          }

          .txt-name{
              font-size: 14px;
              color: #0587FF;
              font-weight: bold;
          }
      }

      button{
        display: flex;
        min-width: 122px;
        justify-content: flex-start;

        @media only screen and (max-width: 980px) {
            min-width: auto;
        }

        .material-icons{
            font-size: 20px;
            color: #989898;
            margin: 0 2px;
        }

        .txt{
            font-size: 12px;
            color: #989898;
            text-transform: capitalize;
            display: flex;
            padding: 2px 5px 0;

            @media only screen and (max-width: 980px) {
                padding: 0;
                font-size: 11px;
            }
        }

        &:hover{
            background: none;

            .material-icons, .txt{
                color: #0587FF;
            }
        }
      }
      iframe{
          padding: 0 10px;
          margin: 10px 0;
          border: none;
      }
    }
`;

export const ContainerCorreios = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 650px;
`

export const CodigoRastreioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 38px;
  background: #F0F8FF;
  border-top: 1px solid #DDEFFF;
`

export const CodigoRastreio = styled.span`
  font-size: 12px;
  color: #004482;

  font-weight: ${({ weight }) =>
    weight === 'bold' ? 700 : 400}
`

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  z-index: 1;
`

export const Map = styled.img`
  object-fit: cover;
`;

export const DeliveryStateWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 470px;
  margin-top: -29px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 8px 12px #122d4710;
  z-index: 2;
`

export const DeliveryState = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 79px;
  border-bottom: 1px solid #F2F2F2;

  @media (max-width: 550px) {
    flex-direction: column;
    height: max-content;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  transition: background-color 0.2s linear;

  &:hover {
    background-color: #F0F8FF;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: ${({ type }) =>
    type == 'min' ? '128px' : '204px'};
  height: ${({ type }) =>
    type == 'min' && '42px'
  };

 @media (max-width: 550px) {
   align-items: center;
   width: 100%;
  height: max-content;
  }
`

export const Title = styled.span`
  display: -webkit-box;
  max-height: 20px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #224261;
  font-size: 14px;
  font-weight: 700;
  text-align: left;

  @media (max-width: 550px) {
    -webkit-line-clamp: initial;
    max-height: fit-content;
    margin: 8px 0;
    font-size: 13px;
    text-align: center;
  }
`

export const Icon = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-right: ${({ mr }) => mr};
`

export const Date = styled.span`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: #435F7A;
`

export const Localization = styled.span`
  display: flex;
  font-size: 12px;
  color: #A9B8C7;
  margin-top: 6px;
  `

export const LocalizationCity = styled.span`
  display: -webkit-box;
  max-height: 17px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 3px;
  text-align: left;
  font-size: 12px;
  color: #224261;
`

export const ButtonSC = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  width: 330px;
  height: 33px;
  background-color: #0587FF;
  font-size: 14px;
  color: #FFFFFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  transition: background-color 0.2s linear;

  &:hover {
    background-color: #0580E0;
  }
`

export const HaulerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HaulerText = styled.span`
  margin: 16px 0 8px;
  font-size: 11px;
  color: #737373;
`

export const HaulerImg = styled.img`
  max-width: 130px;
  height: auto;
  margin-bottom: 16px;
`

export const CorreiosSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 27px 24px;
  margin-top: 64px;
  width: 100%;
  max-width: 490px;
  min-height: 146px;
  background: linear-gradient(271.07deg, #EEBB02 10.4%, #FDCC27 77.15%);
  box-shadow: 0px 2px 12px rgba(18, 45, 71, 0.1);
  border-radius: 8px;

  @media (max-width: 410px) {
    flex-direction: column;
  }
`

export const CorreiosContentImg = styled.img`
   max-width: 117px;
   max-height: 106px;
   margin-right: 16px;

   @media (max-width: 410px) {
    margin: 0 0 12px;
  }
`

export const CorreiosContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 410px) {
    align-items: center;
  }
`

export const CorreiosContentTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  color: #064E97;
  margin: 0;
`

export const CorreiosContentSubtitle = styled.span`
  margin-bottom: 10px;
  font-size: 12px;
  color: #064E97; 
`

export const CorreiosRedirectButton = styled.button`
  position: relative;
  width: 148px;
  height: 36px;
  padding: 10px 24px;
  border-radius: 8px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #064E97; 
  background: #FFFFFF; 
  cursor: pointer;

  transition: background-color 0.2s linear, color 0.2s linear;

  &:hover {
    color: #FFFFFF;
    background-color: #064E97;
  }
`

export const ChevronRightIcon = styled.img`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`