export const paises = {
    'brasil': 'Brazil',
    'estados unidos da américa': 'The united states of América',
    'estados unidos': 'The united states of América',
    'reino unido': 'United Kingdom',
    'inglaterra': 'England',
    'australia': 'Australia',
    'nova zelandia': 'New Zealand',
    'africa do sul': 'South Africa',
    'irlanda': 'Ireland',
    'canada': 'Canada',
    'india': 'India',
    'belgica': 'Belgium',
    'dinamarca': 'Denmark',
    'egito': 'Egypt',
    'finlandia': 'Finland',
    'franca': 'France',
    'alemanha': 'Germany',
    'grecia': 'Greece',
    'islandia': 'Iceland',
    'italia': 'Italy',
    'japao': 'Japan',
    'letonia': 'Latvia',
    'mexico': 'Mexico',
    'coreia do norte': 'North Korea',
    'noruega': 'Norway',
    'polonia': 'Poland',
    'portugal': 'Portugal',
    'russia': 'Russia',
    'Escocia': 'Scotland',
    'singapura': 'Singapore',
    'espanha': 'Spain',
    'suecia': 'Sweden',
    'suica': 'Switzerland',
    'tailandia': 'Thailand',
    'turquia': 'Turkey',
    'ucrania': 'Ukraine',
    'vietna': 'Vietnam',
    'argentina': 'Argentina',
    'china': 'China',
    'bulgaria': 'Bulgaria',
    'republica dominicana': 'Dominican Republic',
    'indonesia': 'Indonesia',
    'quenia': 'Kenya',
    'libano': 'Lebanon',
    'paquistao': 'Pakistan',
    'eslovaquia': 'Slovakia',
    'holanda': 'Holland',
    'haiti': 'Haiti',
    'hungria': 'Hungary',
    'afeganistao': 'Afghanistan',
    'lituania': 'Lithuania'
}