import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './styles.css';

export function Mapa({ latlong, cidadePais }) {
    const cidadeOuPaisZoom = cidadePais.includes('-') ? 11 : 3

    const geo = {
        centro: [latlong.latitude, latlong.longitude],
        zoom: cidadeOuPaisZoom,
    }

    const maxBounds = {
        position1: [latlong.latitude + 0.15, latlong.longitude + 0.5],
        position2: [latlong.latitude - 0.15, latlong.longitude - 0.5],
    }

    return (
        <div className="map">
            <MapContainer
                center={geo.centro}
                zoom={geo.zoom}
                minZoom={cidadeOuPaisZoom - 1}
                maxZoom={cidadeOuPaisZoom + 1}
                scrollWheelZoom={false}
                maxBounds={[maxBounds.position1, maxBounds.position2]}
                maxBoundsViscosity={1}
            >
                <TileLayer
                    attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                />
            </MapContainer>
        </div>
    );
}