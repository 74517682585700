import { useEffect, useState } from 'react'
import axios from 'axios'
import history from '../../services/history';
import { Mapa } from './Mapa';
import { DateFormat } from '../../utils/DateFormat'
import { siglasEstados } from '../../utils/siglasEstados';
import { paises } from '../../utils/paises';

import {
    ButtonSC,
    CodigoRastreio,
    CodigoRastreioContainer,
    ContainerCorreios,
    Date,
    DeliveryState,
    DeliveryStateWrapper,
    HaulerImg,
    HaulerText,
    HaulerWrapper,
    Icon,
    InfoContainer,
    Localization,
    LocalizationCity,
    MapContainer,
    Title,
} from '../../pages/layout/styles';

import truckImg from '../../assets/truck.svg'
import calendarImg from '../../assets/icon-calendar.svg'
import correiosImg from '../../assets/logo-correios.svg'
import mapBoxConfig from '../../config/mapbox';

export function RastreioCorreios({ rastreio }) {
    const [isLoading, setIsLoading] = useState(true)
    const [latlong, setLatlong] = useState({
        latitude: '',
        longitude: ''
    })

    const localidade = rastreio.ocorrencias[0].origem
    const cidade = localidade.slice(0, localidade.length - 5)
    const estado = localidade.slice(localidade.length - 2, localidade.length)
    const paisFormatado = paises[localidade.toLowerCase()] ? paises[localidade.toLowerCase()] : rastreio.ocorrencias[0].origem
    const buscaFormatada = localidade.includes('-') ? `${cidade} - ${siglasEstados[estado]}` : paisFormatado
    const cidadePais = localidade.includes('-') ? 'city' : 'country'

    const { mapBoxToken } = mapBoxConfig;

    async function GerarLatitudeLongitude() {
        await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${buscaFormatada}.json?&type=${cidadePais}&access_token=${mapBoxToken}`)
            .then(data =>
                setLatlong({
                    latitude: data.data.features[0].center[1],
                    longitude: data.data.features[0].center[0]
                })
            )
        setIsLoading(false)
    }

    useEffect(() => {
        GerarLatitudeLongitude()
    }, [])

    return (
        <ContainerCorreios>
            <CodigoRastreioContainer>
                <CodigoRastreio>Código de Rastreio</CodigoRastreio>
                <CodigoRastreio weight="bold">{rastreio.codigoRastreio}</CodigoRastreio>
            </CodigoRastreioContainer>

            <MapContainer>
                {!isLoading &&
                    <Mapa
                        latlong={latlong}
                        cidadePais={localidade}
                    />
                }
            </MapContainer>
            <DeliveryStateWrapper>
                {rastreio.ocorrencias.map(ocorrencia => {
                    return (
                        <DeliveryState key={ocorrencia.dataHora}>
                            <Icon width="40px" height="40px" src={truckImg} />
                            <InfoContainer>
                                <Title
                                    title={ocorrencia.descricao}
                                >
                                    {ocorrencia.descricao}
                                </Title>
                                <Localization>
                                    {ocorrencia.destino ? 'De:' : 'Em:'}
                                    <LocalizationCity title={ocorrencia.origem}>
                                        {ocorrencia.origem}
                                    </LocalizationCity>
                                </Localization>
                            </InfoContainer>
                            <InfoContainer type='min'>
                                <Date>
                                    <Icon width="16px" height="16px" mr="10px" src={calendarImg} />
                                    {DateFormat(ocorrencia.dataHora)}
                                </Date>
                                <Localization>
                                    {ocorrencia.destino &&
                                        <>
                                            Para:
                                            <LocalizationCity
                                                title={ocorrencia.destino}
                                            >
                                                {ocorrencia.destino}
                                            </LocalizationCity>
                                        </>
                                    }
                                </Localization>
                            </InfoContainer>
                        </DeliveryState>
                    )
                })}
            </DeliveryStateWrapper>
            <ButtonSC
                onClick={() => history.push('/correios')}
            >
                Realizar nova consulta
            </ButtonSC>
            <HaulerWrapper>
                <HaulerText>
                    Transportadora Responsável
                </HaulerText>
                <HaulerImg src={correiosImg} />
            </HaulerWrapper>
        </ContainerCorreios >
    )
}