import { useEffect, useState } from 'react';
import RastreioServices from '../services/rastreioServices';
import history from '../services/history';
import { toast } from 'react-toastify';

/**
 * @returns {*}
 */
const useRastreio = (codigoRastreio, recaptchaKey) => {

    const [state, setState] = useState({
        loading: true,
        rastreio: null
    });

    useEffect(() => {
        async function getRatreio() {

            RastreioServices.getRastreio(codigoRastreio, recaptchaKey)
                .then((response) => {
                    setState({
                        rastreio: response,
                        loading: false,
                    });
                })
                .catch((error) => {
                    // toast.("Código de Rastreio não encontrado.")
                    setState({ rastreio: false, loading: false });
                    if (window.location.pathname.includes('/correios')) {
                        history.push('/correios', codigoRastreio);
                    } else {
                        toast.error("Código de Rastreio não encontrado.");
                        history.push('/');
                    }
                });
        }
        getRatreio();
        // eslint-disable-next-line
    }, []);
    return { state, setState };
}
export default useRastreio;
