import styled from 'styled-components';

export const Loadingstyles = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s linear 1.3s;

  .circle-progress{
    position: relative;
    z-index: 1;
    transition: 1s;
    outline: 4px solid #D7EBFF;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    outline-offset: -4px;
  }

  .box-animation{
    width: 100%;
    position: relative;
    display: flex;
    place-items: center;
    flex-direction: column;
  }

  .box-initial{
    transition: all 0.8s linear 0.4s;
  }

  .box-finish{
    position: absolute;
    display: flex;
    place-items: center;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100px);
    -webkit-transform: translateX(-100px);
    transition: all 0.8s linear 0.5s;
    top: 0;
    bottom: 0;
    margin: auto;
    justify-content: center;

    .icon-check{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #27AE60;
        display: flex;
        place-items: center;
        justify-content: center;
    }

    .txt-sucess{
        font-size: 16px;
        color: #27AE60;
        font-weight: 700;
        margin-top: 30px;
    }
  }

  .box-progress{
      width: 100%;
      max-width: 200px;
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      position: relative;

      .bar-progress{
          position: absolute;
          width: 100%;
          height: 3px;
          background: #F2F2F2;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;

          .bar{
              width: 0;
              height: 100%;
              top: 0;
              left: 0;
              background: #0587FF;
              position: absolute;
              transition: 3s linear;
          }
      }

      .circle{
          width: 40px;
          height: 40px;
          display: flex;
          place-items: center;
          justify-content: center;
          border: 2px solid #F2F2F2;
          box-sizing: border-box;
          border-radius: 50%;
          position: relative;
          background: #fff;
          transition: border 0.3s linear;
          

          &.step-1{
            transition-delay: 0.5s;
          }

          &.step-2{
            transition-delay: 1.5s;
          }

          &.step-3{
            transition-delay: 2.5s;
          }
      }
  }

  &.active{
      width: 100%;
    .circle{
        border: 2px solid #0587FF;
    }

    .box-progress .bar-progress .bar{
        width: 100%;
    }

    &.finish{
        .circle{
            transition-delay: 0.1s;
            border: 2px solid #27AE60;
        }

        .box-progress .bar-progress .bar{
           transition: 0.1s;
           background: #27AE60;
        }

        .circle-progress{
            transform: translateY(100px);
            -webkit-transform: translateY(100px);
            opacity: 0;
        }

        .box-initial{
            transform: translateX(100px);
            -webkit-transform: translateX(100px);
            opacity: 0;
        }

        .box-finish{
            transform: translateX(0);
            -webkit-transform: translateX(0);
            opacity: 1;
            visibility: visible;
        }

        opacity: 0;
    }

  }

  .txt-loading {
      margin-top: 40px;
      display: block;

    p {
        font-size: 15px;
        color: #737373;
    }

  }

`;