import React from 'react';
import { Loadingstyles } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import search  from '../../assets/icon-search.svg';
import pack  from '../../assets/icon-pack.svg';
import truck  from '../../assets/icon-truck.svg';
import check  from '../../assets/icon-check.svg';


const Loading = () => {

  return (
    <Loadingstyles id="progress">
      <div className="circle-progress">
        <CircularProgress  size={60} style={{'color': '#0587FF'}}/>
      </div>
    <div className="box-animation">
        <div className="box-finish">
            <span className="icon-check"><img src={check} alt="Concluído"></img></span>
            <span className="txt-sucess">Busca concluída com sucesso!</span>
        </div>
        <div className="box-initial">
            <div className="box-progress">
                <div className="bar-progress"><span className="bar"></span></div>
                <span className="circle step-1"><img src={search} alt="Lupa de pesquisa"></img></span>
                <span className="circle step-2"><img src={pack} alt="Pacote"></img></span>
                <span className="circle step-3"><img src={truck} alt="Caminhão"></img></span>
            </div>
            <span className="txt-loading">
                <p>Buscando informações da</p>
                <p>transportadora responsável.</p>
            </span>
        </div>
    </div>
    </Loadingstyles>
  );
};

export default Loading;
